import React, { useEffect, useContext, useState } from "react";
import { Instagram } from "react-content-loader";
import { getProfile } from "../../apis/index.js";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AppContext from "../useContext/useContext";
import { setProfileUser } from "../../utils/localStorage";
import { onCLoseWebView } from "../../utils/utils.js";
import ModalFailedLogin from "../Modal/modalFailed.jsx";

function Auth() {
  const navigate = useNavigate();
  const { setAToken } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const search_user_id = searchParams.get('user_id')
  const {user_id} = useParams()
  const [openFailedLogin, setOpenFailedLogin] = useState(false);
  const user_id_info = !!search_user_id ? search_user_id : user_id
  const handleGetProfileUser = async () => {
    const { data: user, success } = await getProfile(user_id_info);
    if (success) {
      const { token_user } = user;
      setProfileUser(user);
      setAToken(token_user);
      navigate("/");
    } else {
      setOpenFailedLogin(true);
    }
  };

  useEffect(() => {
    if (user_id_info) {
      handleGetProfileUser();
    }
  }, [user_id_info]);

  return (
    <section className="vh-100">
      <div className="container py-5 h-100">
        <div className="row auth-placeholder">
          <Instagram />
        </div>
      </div>
      <ModalFailedLogin
        open={openFailedLogin}
        onNavigateToRoot={() => onCLoseWebView()}
      />
    </section>
  );
}

export default Auth;
